import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import { getAvisos } from '../components/dataController';
import { UserContext } from '../contexts/UserContext';
import { Grid, Backdrop, CircularProgress, Button, Modal, Container } from "@material-ui/core";
import WebLayout from '../components/WebLayout';
import HomeContent from '../components/HomeContent';
import Telefonia from '../contexts/Webpages/Telefonia';
import Telefoniafija from '../contexts/Webpages/Telefoniafija';
import Internet from '../contexts/Webpages/Internet';
import Cobertura from '../contexts/Webpages/Cobertura';
import Contacto from '../contexts/Webpages/Contacto';
import Recargasp from '../contexts/Webpages/RecargasP';
import Distribuidor from '../contexts/Webpages/Distribuidor';
import Recuperacion from '../contexts/Webpages/Recuperacion';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';
import AfiliaForm from '../pages/AfiliaForm';
import ReactWhatsapp from 'react-whatsapp';
import TawkToChat from '../components/TawkToChat';


const styles = theme => ({
    backgroundColor: '#000',
    float: {
        position: 'fixed',
        width: 60,
        height: 60,
        bottom: 40,
        right: 40,
        backgroundColor: '#25d366',
        color: '#FFF',
        borderRadius: 50,
        textAlign: 'center',
        fontSize: 30,
        boxShadow: '2px 2px 3px #999',
        zIndex: 100,
    },
    myFloat: {
        marginTop: 16,
        width: 30,
        height: 30,
    }
});

const Home = ({ classes }) => {
    const [afiliaForm, setAfiliaForm] = useState(false);
    const [msg, setMsg] = useState(false);
    const [message, setMessage] = useState('FTE');
    const [severity, setSeverity] = useState('error');
    const { login } = useContext(UserContext);
    const location = useLocation(); // useLocation hook to get location data
    const currentDomain = window.location.hostname;
    const isDashUrl = location.pathname.includes('/dash');

    const hash = location.hash || '#inicio'; // Default to #inicio if no hash is present

    useEffect(() => {



        login((res) => {
            // this is for the mi.fte.mx version    
            if (currentDomain == "mi.fte.mx" && !isDashUrl) {
                //window.location = '/dash'
            }


        });
        console.log("not logged in ")
        getMessages();
    }, []);

    const getMessages = () => {
        getAvisos().then(res => {
            if (res && res.length > 0) {
                setMsg(true);
                setSeverity(res[0].tipo);
                setMessage(res[0].mensaje);
            }
        })
        .catch(error => {
            console.error('Error fetching avisos:', error);
        });
    };

    const openAfilia = () => setAfiliaForm(true);
    const closeAfilia = () => setAfiliaForm(false);

    return (
        <div>
            <TawkToChat />
            {/* <ReactWhatsapp
                style={{
                    position: 'fixed',
                    cursor: 'pointer',
                    border: '1px solid #fff',
                    bottom: 20,
                    right: 20,
                    width: '70px',
                    height: '70px',
                    textAlign: 'center',
                    backgroundColor: '#25d366',
                    borderRadius: '50%',
                    boxShadow: '0px 4px 10px 0px rgb(0 0 0 / 15%)'
                }}
                number="+524428944194"
                message="Hola me gustaría información adicional."
            >
                <svg
                    style={{ fill: '#fff', padding: ',5rem', width: '50px', height: '50px' }}
                    focusable="false"
                    viewBox="0 0 24 24"
                    width="55"
                    height="55"
                >
                    <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
                </svg>
            </ReactWhatsapp> */}



            <Container style={{ paddingBottom: 0, paddingRight: 20, paddingLeft: 20 }} maxWidth="lg">
                {msg && (
                    <Alert variant="filled" severity={severity} onClose={() => setMsg(false)}>
                        {message}
                    </Alert>
                )}
            </Container>

            <WebLayout tab={hash}>
                {hash === '#inicio' && (
                    <div id='#inicio'>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <HomeContent afif={openAfilia} closeAfilia={closeAfilia} />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {hash === '#telefonia' && (
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Telefonia afif={openAfilia} closeAfilia={closeAfilia} />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {hash === '#cobertura' && (
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Cobertura />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {hash === '#recargasp' && (
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Recargasp />
                            </Grid>
                        </Grid>
                    </div>
                )}
                {/* Repeat for other sections */}
            </WebLayout>

            <Modal
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                aria-labelledby="afiliar"
                aria-describedby="afiliacion"
                className={classes.modal} // use classes instead of theme
                open={afiliaForm}
                onClose={closeAfilia}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={afiliaForm}>
                    <div className={classes.paper}> {/* use classes instead of theme */}
                        <AfiliaForm closeAfilia={closeAfilia} />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default withStyles(styles)(Home);
